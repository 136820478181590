import cn from "classnames";
import { ResponsiveImage } from "components/shared/ResponsiveImage";
import { Title } from "components/typography/Title";
import dynamic from "next/dynamic";
import Image, { ImageProps } from "next/image";

const SimpleContentParser = dynamic(() => import("components/shared/SimpleContentParser"));

export type SubheadingSize = "small" | "default" | "big";

export type HeadingProps = {
  title: string;
  titleLevel?: number;
  titleTag?: number;
  subheading?: string | null;
  subheadingSize?: SubheadingSize;
  type?: "regular" | "promo";
  align?: "left" | "center";
  alignMd?: "left" | "center";
  image?:
    | string
    | (Omit<ImageProps, "alt"> & {
        alt?: string;
      });
  className?: string;
};

export const Heading = ({
  title,
  subheading,
  subheadingSize = "default",
  className,
  image,
  type = "regular",
  align = "center",
  alignMd = "center",
  titleLevel,
  titleTag,
}: HeadingProps) => {
  const level = titleLevel || (type === "promo" ? 1 : 2);

  const subheadingClassNames = cn(
    "sc-heading__subheading",
    `sc-heading__subheading--size-${subheadingSize}`,
    className
  );

  return (
    <div
      className={cn(
        "sc-heading",
        `sc-heading--type-${type}`,
        `sc-heading--align-${align}`,
        `sc-heading--align-md-${alignMd}`,
        className
      )}
    >
      {image && (
        <div className="sc-heading__image">
          {typeof image === "string" ? (
            <Image quality={100} src={image} width={60} height={60} alt="Illustration" />
          ) : (
            <Image quality={100} {...image} alt={image.alt || "Illustration"} />
          )}
        </div>
      )}
      <Title level={level} tag={titleTag} html={title} className={cn("sc-heading__title")} />
      {subheading && (
        <SimpleContentParser
          content={subheading}
          className={cn("sc-heading__content", subheadingClassNames)}
        />
      )}
    </div>
  );
};

export default Heading;
