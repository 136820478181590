"use client";
import { Page } from "components/layout/Layout";
import "@/styles/critical/_section-promo.scss";
import { SectionPromo } from "@/components/shared/sections/SectionPromo";
import { useErrorPage } from "@/helpers/useErrorPage";

export default function Custom500() {
  useErrorPage("500 | StudyCrumb");

  return (
    <Page>
      <SectionPromo
        heading="500"
        subheading="Internal server error!"
        button={{
          text: "Go home",
          props: {
            href: "/",
          },
        }}
        height="stretch"
      />
    </Page>
  );
}
