import styles from "./Title.module.scss";
import cn from "classnames";
import type { HTMLAttributes } from "react";
import { upperFirst } from "lodash";

type TitleProps = {
  tag?: number;
  level: number;
  className?: string;
  html?: string;
  variant?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Title = ({ tag, level = 2, children, className, variant, html, ...props }: TitleProps) => {
  const Tag = tag === 0 ? "div" : (`h${tag ? tag : level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6");

  const classValue = cn(
    styles.heading,
    {
      [styles[`level${level}`]]: level > 1,
      ["sc-title-level-1"]: level === 1,
      [styles[`variant${upperFirst(variant)}`]]: !!variant,
    },
    className
  );

  return html ? (
    <Tag className={classValue} dangerouslySetInnerHTML={{ __html: html }} {...props} />
  ) : (
    <Tag className={classValue} {...props}>
      {children}
    </Tag>
  );
};
