import cn from "classnames";
import { Container } from "components/layout/Container";
import { Heading } from "components/layout/Heading";
import { Button, ButtonProps } from "components/UI/Buttons/Button";

import type { BreadcrumbItem } from "types/shared";
import type { ReviewType } from "../review/ReviewItem";
import dynamic from "next/dynamic";
import Image, { ImageProps } from "next/image";
import { IconImageType } from "@/types/icon";
import { IconImage } from "@/components/UI/IconImage";
import { PropsWithChildren } from "react";

const PriceCalculator = dynamic(() => import("components/shared/PriceCalculator"));
const Breadcrumbs = dynamic(() => import("components/shared/Breadcrumbs"));
const ReviewBadges = dynamic(() => import("components/shared/review/ReviewBadges"));

export type SectionPromoProps = PropsWithChildren<{
  heading: string;
  titleLevel?: number;
  subheading?: string | null;
  type?: "center" | "cols" | "calculator";
  image?: Omit<ImageProps, "alt"> & { alt?: string };
  button?: {
    text: string;
    align?: "left" | "center";
    props: ButtonProps;
  };
  breadcrumbs?: BreadcrumbItem[];
  background?: "gradient" | "transparent";
  promoWide?: boolean;
  height?: "auto" | "stretch" | "fullscreen";
  afterButtonText?: string;
  advantages?:
    | string[]
    | {
        img: IconImageType;
        text: string;
      }[];
  reviews?: {
    heading?: string;
    items: {
      type: ReviewType;
      rate: number;
      link?: string;
    }[];
  };
  className?: string;
}>;

export const SectionPromo = ({
  heading,
  subheading,
  breadcrumbs,
  button,
  image,
  type = "center",
  background = "gradient",
  promoWide,
  children,
  height = "auto",
  titleLevel,
  afterButtonText,
  advantages,
  reviews,
  className,
}: SectionPromoProps) => {
  return (
    <section
      className={cn(
        "sc-section-promo",
        `sc-section-promo--type-${type}`,
        `sc-section-promo--background-${background}`,
        `sc-section-promo--height-${height}`,
        {
          ["sc-section-promo--wide"]: promoWide,
          ["sc-section-promo--with-breadcrumbs"]: !!breadcrumbs,
          ["sc-section-promo--with-reviews"]: !!reviews,
          ["sc-section-promo--with-advantages"]: !!advantages,
        },
        className
      )}
    >
      {background === "gradient" && (
        <div className="sc-section-promo__bg">
          <Image width={2800} height={1418} src="/img/gradient-bg.jpg" quality={100} alt="Background" />
        </div>
      )}
      <Container className="sc-section-promo__container">
        {breadcrumbs && <Breadcrumbs list={breadcrumbs} className="sc-section-promo__breadcrumbs" />}
        <div className="sc-section-promo__inner">
          <div className="sc-section-promo__left">
            <Heading
              align={type === "center" ? "center" : "left"}
              title={heading}
              subheading={subheading}
              type="promo"
              subheadingSize={type === "calculator" ? "big" : "default"}
              titleLevel={titleLevel}
              titleTag={1}
              image={type === "cols" ? undefined : image}
              className="sc-section-promo__heading"
            />
            {advantages && (
              <ul className="sc-section-promo__advantages">
                {advantages.map((item, index) => (
                  <li key={index}>
                    {typeof item === "string" ? (
                      <Image
                        src="/img/bubble-bullet.png"
                        width={28}
                        height={24}
                        quality={100}
                        priority
                        alt="Illustration"
                        className="sc-section-promo__bullet"
                      />
                    ) : (
                      <IconImage icon={item.img} size={40} priority />
                    )}
                    {typeof item === "string" ? item : item.text}
                  </li>
                ))}
              </ul>
            )}
            {button && (
              <div
                className={cn("sc-section-promo__btn-wrap", {
                  ["sc-section-promo__btn-wrap--align-center"]: button.align === "center",
                })}
              >
                <Button {...button.props}>{button.text}</Button>
                {afterButtonText && <p className="sc-section-promo__after-button-text">{afterButtonText}</p>}
              </div>
            )}
            {children}
          </div>
          <div className="sc-section-promo__right">
            {type === "cols" && image && (
              <Image {...image} alt={image.alt || heading} priority quality={100} />
            )}
            {type === "calculator" && <PriceCalculator />}
          </div>
        </div>
        {reviews && (
          <div className="sc-section-promo__reviews">
            <p className="sc-section-promo__badges-title">{reviews.heading}</p>

            <ReviewBadges items={reviews.items} />
          </div>
        )}
      </Container>
    </section>
  );
};
