import { useEffect } from "react";

export const useErrorPage = (title: string) => {
  useEffect(() => {
    document.title = title;
    document.head.insertAdjacentHTML(
      "beforeend",
      `
    <meta name="viewport" content="width=device-width" />
    <meta name="theme-color" content="#2d4eed" media="(prefers-color-scheme: light)">
    <meta name="theme-color" content="#2d4eed" media="(prefers-color-scheme: dark)">
    <link rel="apple-touch-icon" sizes="64x64" href="https://studycrumb.com/img/favicon/size-64.png">
    <link rel="apple-touch-icon" sizes="128x128" href="https://studycrumb.com/img/favicon/size-128.png">
    <link rel="apple-touch-icon" sizes="192x192" href="https://studycrumb.com/img/favicon/size-192.png">
    <link rel="apple-touch-icon" sizes="256x256" href="https://studycrumb.com/img/favicon/size-256.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://studycrumb.com/img/favicon/favicon.png"
    ><link rel="icon" href="https://studycrumb.com/img/favicon/favicon.png">
    `
    );

    // workaround for sprite mounting
    // @ts-ignore
    const sprite = window["__SVG_SPRITE__"];
    // sprite.mount(document.body, true);

    const spriteElement = document.getElementById("__SVG_SPRITE_NODE__");

    if (sprite && !spriteElement) {
      document.body.insertBefore(sprite.node, document.body.firstChild);
    }

    // NEXT JS BUG: next links not working correctly on custom error pages
    const links = Array.from(document.querySelectorAll("a"));

    const handleLinkClick = (event: MouseEvent) => {
      if (event.target && "href" in event.target) {
        location.href = String((event.target as HTMLAnchorElement).href);
      }
    };

    links.forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, [title]);
};
